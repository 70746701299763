<template>
    
    <v-container fluid>

      <!-- Section Path File/Modul -->
      <v-breadcrumbs 
          :items="pathModul"
          divider="-"
          normal
          class="pathModul"
      ></v-breadcrumbs>

      <div class="px-5">

        <v-row>
          <v-col>

            <v-btn
            elevation="2"
            color="button"
            :class="selectedBase == 'case list'? 'white--text mr-4' : 'mr-4'"
            :outlined="selectedBase == 'case list'? false : true"
            @click="selectedBase = 'case list'"
            >
              <v-icon class="mr-2">mdi-format-list-bulleted</v-icon>
              Case List
            </v-btn>

            <v-btn
            elevation="2"
            color="button"
            :class="selectedBase == 'data entry form'? 'white--text mr-4' : 'mr-4'"
            :outlined="selectedBase == 'data entry form'? false : true"
            @click="selectedBase = 'data entry form'"
            >
              <v-icon class="mr-2">mdi-list-box-outline</v-icon>
              Data Entry Form
            </v-btn>

          </v-col>
        </v-row>

      </div>

      <div v-show="selectedBase == 'case list'" class="px-5">

        <v-row>
          <v-col cols="4">
            <v-select
            :items="listYear"
            v-model="modelSelectYear"
            label="Year"
            class=""
            prepend-inner-icon="mdi-calendar-blank"
            solo
            dense
            hide-details
            ></v-select>
          </v-col>
          <v-col cols="2">
            <v-btn
            elevation="2"
            color="button"
            class="white--text"
            :disabled="modelSelectYear == ''"
            @click="generateReportCaseList()"
            >
              Generate Report
            </v-btn>
          </v-col>
        </v-row>

        <v-row>
          <v-col>

            <v-data-table
            :headers="thead_caselist"
            :items="tbody_caselist"
            :header-props="{ sortIcon: null }"
            class="elevation-1 headerDtSarawak"
            :items-per-page="5"
            no-select-on-click
            :custom-sort="customSort"
            :loading="loadingTableCaseList"
            >
            
              <template v-slot:[`item.Action`]="{ item }">

                <v-icon color="primary" dense :class="'mx-3'+item.Action" @click="openFormEditCaseList(item)">
                  mdi-note-edit
                </v-icon>

                <v-icon color="red" dense class="mx-3" @click="openConfirmationDeleteCaseList(item)">
                  mdi-delete
                </v-icon>

              </template>
            
            </v-data-table>

          </v-col>
        </v-row>

        <!-- Dialog Form -->
        <v-dialog
        v-model="dialogForm"
        scrollable
        max-width="1000px"
        >
          <v-card>

            <v-card-title class="card-header white--text">
              <v-icon class="mr-4 white--text">mdi-note-edit</v-icon>
              Edit Case
            </v-card-title>

            <v-divider></v-divider>

            <v-card-text style="height: 700px;">
              
              <v-row>

                <v-col>
                  <v-text-field
                  v-model="payloadFormEdit.reportid"
                  label="Case ID **"
                  outlined
                  dense
                  hide-details
                  readonly
                  ></v-text-field>
                </v-col>

                <v-col cols="5">
                  <v-text-field
                  v-model="payloadFormEdit.casetitle"
                  label="Case Title **"
                  outlined
                  dense
                  hide-details
                  ></v-text-field>
                </v-col>

                <v-col>
                  <v-text-field
                  v-model="payloadFormEdit.casename"
                  label="Case Name **"
                  outlined
                  dense
                  hide-details
                  ></v-text-field>
                </v-col>

                <v-col>
                  <!-- <v-text-field
                  v-model="payloadFormEdit.state"
                  label="State **"
                  outlined
                  dense
                  hide-details
                  ></v-text-field> -->
                  <v-select
                  :items="listState"
                  v-model="payloadFormEdit.state"
                  label="State **"
                  outlined
                  dense
                  hide-details
                  ></v-select>
                </v-col>

              </v-row>

              <v-row>

                <v-col>
                  <v-menu
                  v-model="modelFromCalendar"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                        v-model="payloadFormEdit.casestartdate"
                        prefix="From ** :"
                        prepend-inner-icon="mdi-calendar-start"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        hide-details
                        outlined
                        dense
                        ></v-text-field>
                    </template>
                    <v-date-picker
                    v-model="payloadFormEdit.casestartdate"
                    @input="modelFromCalendar = false"
                    >
                        <v-spacer></v-spacer>
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col>
                  <v-menu
                  v-model="modelToCalendar"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                        v-model="payloadFormEdit.caseenddate"
                        prefix="To ** :"
                        prepend-inner-icon="mdi-calendar-start"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        hide-details
                        outlined
                        dense
                        ></v-text-field>
                    </template>
                    <v-date-picker
                    v-model="payloadFormEdit.caseenddate"
                    @input="modelToCalendar = false"
                    >
                        <v-spacer></v-spacer>
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col>
                  <v-text-field
                  v-model="payloadFormEdit.latitude"
                  prefix="Latitude ** :"
                  outlined
                  dense
                  hide-details
                  @keypress="isNumber($event)"
                  append-icon="mdi-latitude"
                  ></v-text-field>
                </v-col>

                <v-col>
                  <v-text-field
                  v-model="payloadFormEdit.longitude"
                  prefix="Longitude ** :"
                  outlined
                  dense
                  hide-details
                  @keypress="isNumber($event)"
                  append-icon="mdi-longitude"
                  ></v-text-field>
                </v-col>

              </v-row>

              <v-row>

                <v-col>
                  <div>
                    <span>Report:</span>
                  </div>
                  <div>
                    <v-file-input
                    outlined
                    dense
                    hide-details
                    placeholder="Upload File"
                    prepend-icon=""
                    prepend-inner-icon="mdi-paperclip"
                    v-model="filesEdit.fullreport"
                    @change="uploadFile('fullreport')"
                    ></v-file-input>
                  </div>
                  <div class="mt-1">
                    <v-btn
                    small
                    class="font-weight-bold px-0"
                    color="primary"
                    elevation="0"
                    text
                    :disabled="payloadFormEdit.fullreport == '' || payloadFormEdit.fullreport == null"
                    @click="viewFile(payloadFormEdit.fullreport)"
                    >
                      <v-icon small dark class="mr-1">mdi-file-eye</v-icon>
                      Open File
                    </v-btn>
                  </div>
                </v-col>

                <v-col>
                  <div>
                    <span>COC:</span>
                  </div>
                  <div>
                    <v-file-input
                    outlined
                    dense
                    hide-details
                    placeholder="Upload File"
                    prepend-icon=""
                    prepend-inner-icon="mdi-paperclip"
                    v-model="filesEdit.coc"
                    @change="uploadFile('coc')"
                    ></v-file-input>
                  </div>
                  <div class="mt-1">
                    <v-btn
                    small
                    class="font-weight-bold px-0"
                    color="primary"
                    elevation="0"
                    text
                    :disabled="payloadFormEdit.coc == '' || payloadFormEdit.coc == null"
                    @click="viewFile(payloadFormEdit.coc)"
                    >
                      <v-icon small dark class="mr-1">mdi-file-eye</v-icon>
                      Open File
                    </v-btn>
                  </div>
                </v-col>

                <v-col>
                  <div>
                    <span>Executive Summary:</span>
                  </div>
                  <div>
                    <v-file-input
                    outlined
                    dense
                    hide-details
                    placeholder="Upload File"
                    prepend-icon=""
                    prepend-inner-icon="mdi-paperclip"
                    v-model="filesEdit.executivesummary"
                    @change="uploadFile('executivesummary')"
                    ></v-file-input>
                  </div>
                  <div class="mt-1">
                    <v-btn
                    small
                    class="font-weight-bold px-0"
                    color="primary"
                    elevation="0"
                    text
                    :disabled="payloadFormEdit.executivesummary == '' || payloadFormEdit.executivesummary == null"
                    @click="viewFile(payloadFormEdit.executivesummary)"
                    >
                      <v-icon small dark class="mr-1">mdi-file-eye</v-icon>
                      Open File
                    </v-btn>
                  </div>
                </v-col>

                <v-col>
                  <div>
                    <span>COA:</span>
                  </div>
                  <div>
                    <v-file-input
                    outlined
                    dense
                    hide-details
                    placeholder="Upload File"
                    prepend-icon=""
                    prepend-inner-icon="mdi-paperclip"
                    v-model="filesEdit.coa"
                    @change="uploadFile('coa')"
                    ></v-file-input>
                  </div>
                  <div class="mt-1">
                    <v-btn
                    small
                    class="font-weight-bold px-0"
                    color="primary"
                    elevation="0"
                    text
                    :disabled="payloadFormEdit.coa == '' || payloadFormEdit.coa == null"
                    @click="viewFile(payloadFormEdit.coa)"
                    >
                      <v-icon small dark class="mr-1">mdi-file-eye</v-icon>
                      Open File
                    </v-btn>
                  </div>
                </v-col>

                <v-col>
                  <div>
                    <span>Sampling Data:</span>
                  </div>
                  <div>
                    <v-file-input
                    outlined
                    dense
                    hide-details
                    placeholder="Upload File"
                    prepend-icon=""
                    prepend-inner-icon="mdi-paperclip"
                    v-model="filesEdit.filesampling"
                    @change="uploadFile('filesampling')"
                    ></v-file-input>
                  </div>
                  <div class="mt-1">
                    <v-btn
                    small
                    class="font-weight-bold px-0"
                    color="primary"
                    elevation="0"
                    text
                    :disabled="payloadFormEdit.filesampling == '' || payloadFormEdit.filesampling == null"
                    @click="viewFile(payloadFormEdit.filesampling)"
                    >
                      <v-icon small dark class="mr-1">mdi-file-eye</v-icon>
                      Open File
                    </v-btn>
                  </div>
                </v-col>

              </v-row>

              <v-row>
                <v-col>
                  <v-textarea
                  v-model="payloadFormEdit.remarks"
                  rows="3"
                  label="Remarks"
                  outlined
                  hide-details
                  ></v-textarea>
                </v-col>
              </v-row>

              <v-divider></v-divider>

              <!-- Sampling Point -->
              <v-row>
                <v-col>

                  <v-row class="black--text text-subtitle-2 text-decoration-underline">
                    <v-col class="py-0">
                      Sample Point:
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                          fab
                          x-small
                          color="primary"
                          class="mb-2 mr-2"
                          v-bind="attrs"
                          v-on="on"
                          @click="addSamplingPointEdit()"
                          >
                            <v-icon>
                              mdi-plus
                            </v-icon>
                          </v-btn>
                          <span class="mt-1 text-subtitle-1">Add Sampling Point</span>
                        </template>
                        <span>Add More Sampling Point</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>

                      <v-expansion-panels v-model="panel">
                        <v-expansion-panel
                        v-for="(item,i) in payloadFormEdit.pointinfo"
                        :key="i"
                        >

                          <v-expansion-panel-header
                          color="primary" 
                          class="white--text expansion-header cust-expansion-header"
                          >
                            <v-row justify="center">

                              <!-- Left -->
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                  fab
                                  x-small
                                  color="red"
                                  class="ma-0 pa-0 cust-remove-sampling-point-btn"
                                  elevation="0"
                                  v-bind="attrs"
                                  v-on="on"
                                  @click.stop="removeSamplingPointEdit(item,i)"
                                  >
                                    <v-icon class="white--text">
                                      mdi-delete-forever
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>Remove this sample point</span>
                              </v-tooltip>

                              <v-spacer></v-spacer>

                              <!-- Center -->
                              <span class="d-flex align-center">
                                <v-icon dense class="white--text">mdi-map-marker</v-icon>
                                Sampling Point {{i+1}}
                              </span>

                              <v-spacer></v-spacer>

                            </v-row>
                          </v-expansion-panel-header>

                          <v-expansion-panel-content>
                            
                            <v-row>

                              <v-col>
                                <v-text-field
                                v-model="item.samplingpoint"
                                label="Point ID **"
                                outlined
                                dense
                                hide-details
                                ></v-text-field>
                              </v-col>

                              <v-col>
                                <v-select
                                :items="listState"
                                v-model="item.pointstate"
                                label="State **"
                                outlined
                                dense
                                hide-details
                                ></v-select>
                              </v-col>

                              <v-col>
                                <v-text-field
                                v-model="item.pointriver"
                                label="River **"
                                outlined
                                dense
                                hide-details
                                ></v-text-field>
                              </v-col>

                            </v-row>

                            <v-row>

                              <v-col>
                                <v-text-field
                                v-model="item.pointlatitude"
                                label="Latitude **"
                                outlined
                                dense
                                hide-details
                                ></v-text-field>
                              </v-col>

                              <v-col>
                                <v-text-field
                                v-model="item.pointlongitude"
                                label="Longitude **"
                                outlined
                                dense
                                hide-details
                                ></v-text-field>
                              </v-col>

                            </v-row>

                            <v-row>

                              <v-col>
                                <v-textarea
                                v-model="item.locationdescription"
                                label="Location Description"
                                outlined
                                rows="4"
                                dense
                                hide-details
                                ></v-textarea>
                              </v-col>

                            </v-row>

                            <v-row>

                              <v-col cols="3">
                                <v-text-field
                                v-model="item.samplingmatrix"
                                label="Sampling Matrix **"
                                outlined
                                dense
                                hide-details
                                ></v-text-field>
                              </v-col>

                              <v-col cols="5" class="d-flex">
                                <v-file-input
                                label="Picture **"
                                outlined
                                dense
                                hide-details
                                prepend-icon="mdi-camera"
                                placeholder="Upload File"
                                v-model="item.pictureInput"
                                @change="uploadFile('picture',i)"
                                ></v-file-input>
                                <v-btn
                                dense
                                class="font-weight-bold px-0 pl-2 pt-2"
                                color="primary"
                                elevation="0"
                                text
                                :disabled="item.picturepath == '' || item.picturepath == null"
                                @click="viewFile(item.picturepath)"
                                >
                                  <v-icon dense dark class="mr-1">mdi-image</v-icon>
                                  Open Image
                                </v-btn>
                              </v-col>

                            </v-row>

                            <v-row>
                              
                              <v-col>

                                <v-card class="px-3" outlined>

                                  <v-row>
                                    <v-col class="d-flex">
                                      <v-btn
                                      fab
                                      x-small
                                      color="primary"
                                      class="mr-2"
                                      @click="addEditFrequency(i)"
                                      >
                                        <v-icon>
                                          mdi-plus
                                        </v-icon>
                                      </v-btn>
                                      <span class="mt-1 text-subtitle-1">Add Frequency</span>
                                    </v-col>
                                  </v-row>

                                  <v-row
                                  v-for="(subitem,x) in item.frequencyinfos"
                                  :key="x"
                                  >

                                    <v-col cols="1" class="text-center pr-0">
                                      <v-btn
                                      color="red"
                                      fab
                                      x-small
                                      dark
                                      @click="removeEditFrequency(i,x)"
                                      >
                                        <v-icon>
                                          mdi-minus
                                        </v-icon>
                                      </v-btn>
                                    </v-col>

                                    <v-col>
                                      <v-menu
                                      v-model="subitem.modelCalendarFrequency"
                                      :close-on-content-click="false"
                                      :nudge-right="40"
                                      transition="scale-transition"
                                      offset-y
                                      min-width="auto"
                                      >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                            label="Date **"
                                            v-model="subitem.frequencyDates"
                                            prepend-inner-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            hide-details
                                            outlined
                                            dense
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                        v-model="subitem.frequencyDates"
                                        @input="subitem.modelCalendarFrequency = false"
                                        >
                                            <v-spacer></v-spacer>
                                        </v-date-picker>
                                      </v-menu>
                                    </v-col>

                                    <v-col>
                                      <v-select
                                      :items="listHours"
                                      v-model="subitem.frequencyHours"
                                      prepend-inner-icon="mdi-clock-time-four-outline"
                                      label="Hours **"
                                      outlined
                                      dense
                                      hide-details
                                      ></v-select>
                                    </v-col>

                                    <v-col>
                                      <v-select
                                      :items="listMinutes"
                                      v-model="subitem.frequencyMins"
                                      prepend-inner-icon="mdi-clock-time-four-outline"
                                      label="Minutes **"
                                      outlined
                                      dense
                                      hide-details
                                      ></v-select>
                                    </v-col>

                                    <v-col>
                                      <v-text-field
                                      v-model="subitem.frequencydescription"
                                      label="Frequency Description **"
                                      outlined
                                      dense
                                      hide-details
                                      ></v-text-field>
                                    </v-col>

                                  </v-row>

                                </v-card>

                              </v-col>

                            </v-row>

                          </v-expansion-panel-content>

                        </v-expansion-panel>
                      </v-expansion-panels>

                    </v-col>
                  </v-row>

                </v-col>
              </v-row>

            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
              dark
              color="button"
              @click="dialogForm = false"
              >
                Cancel
              </v-btn>
              <v-btn
              dark
              color="button"
              @click="saveEditCaseList()"
              >
                Save
              </v-btn>
            </v-card-actions>

          </v-card>
        </v-dialog>

        <!-- Dialog Confirmation Delete Case -->
        <v-dialog
        v-model="dialogConfirmationDelete"
        width="500"
        >
          <v-card>

            <v-card-title
            class="text-h5 white--text" 
            style="background:#ff3737;">
              Delete Case
            </v-card-title>

            <v-card-text style="padding:20px 24px;font-weight:bold;">
              <div>
                Are sure you to delete this case?
              </div>
              <div>
                Case ID: {{selectedCaseToDelete}}
              </div>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
              color="primary"
              @click="dialogConfirmationDelete = false"
              >
                  Cancel
              </v-btn>
              <v-btn
              color="red"
              dark
              @click="yesConfirmDelete(selectedCaseToDelete)"
              >
                  Delete
              </v-btn>
            </v-card-actions>

          </v-card>
        </v-dialog>

      </div>

      <div v-show="selectedBase == 'data entry form'" class="px-5">

        <v-card ref="formCaseEntry">

          <v-card-title class="card-header white--text">
            <v-icon class="mr-4 white--text">mdi-list-box-outline</v-icon>
            Case Details
          </v-card-title>

          <v-divider></v-divider>

          <v-card-text :style="modePreview == true? 'height:700px;overflow-y:auto' : ''">

            <v-row>
              <v-col class="py-1 d-flex">
                <v-icon dense class="mr-1" color="primary">mdi-information</v-icon>
                <div class="text-subtitle-2"><span class="font-weight-black black--text mr-2">**</span>symbols indicates <b>required field</b></div>
              </v-col>
            </v-row>
            
            <v-row>

              <v-col>
                <v-text-field
                v-model="payloadFormEntry.reportid"
                label="Case ID **"
                :outlined="modePreview == false"
                :readonly="modePreview == true"
                :dense="modePreview == false"
                hide-details
                ></v-text-field>
              </v-col>

              <v-col cols="5">
                <v-text-field
                v-model="payloadFormEntry.casetitle"
                label="Case Title **"
                :outlined="modePreview == false"
                :readonly="modePreview == true"
                :dense="modePreview == false"
                hide-details
                ></v-text-field>
              </v-col>

              <v-col>
                <v-text-field
                v-model="payloadFormEntry.casename"
                label="Case Name **"
                :outlined="modePreview == false"
                :readonly="modePreview == true"
                :dense="modePreview == false"
                hide-details
                ></v-text-field>
              </v-col>

              <v-col>
                <!-- <v-text-field
                v-model="payloadFormEntry.state"
                label="State **"
                :outlined="modePreview == false"
                :readonly="modePreview == true"
                :dense="modePreview == false"
                hide-details
                ></v-text-field> -->
                <v-select
                :items="listState"
                v-model="payloadFormEntry.state"
                label="State **"
                :outlined="modePreview == false"
                :readonly="modePreview == true"
                :dense="modePreview == false"
                hide-details
                ></v-select>
              </v-col>

            </v-row>

            <v-row>

              <v-col>
                <v-menu
                v-if="modePreview == false"
                v-model="modelFromCalendarEntry"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                      v-model="payloadFormEntry.casestartdate"
                      prefix="From ** :"
                      prepend-inner-icon="mdi-calendar-start"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      hide-details
                      outlined
                      dense
                      ></v-text-field>
                  </template>
                  <v-date-picker
                  v-model="payloadFormEntry.casestartdate"
                  @input="modelFromCalendarEntry = false"
                  >
                      <v-spacer></v-spacer>
                  </v-date-picker>
                </v-menu>
                <v-text-field
                v-else-if="modePreview == true"
                v-model="payloadFormEntry.casestartdate"
                prefix="From ** :"
                prepend-inner-icon="mdi-calendar-start"
                hide-details
                :outlined="modePreview == false"
                :readonly="modePreview == true"
                :dense="modePreview == false"
                ></v-text-field>
              </v-col>

              <v-col>
                <v-menu
                v-if="modePreview == false"
                v-model="modelToCalendarEntry"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                      v-model="payloadFormEntry.caseenddate"
                      prefix="To ** :"
                      prepend-inner-icon="mdi-calendar-start"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      hide-details
                      outlined
                      dense
                      ></v-text-field>
                  </template>
                  <v-date-picker
                  v-model="payloadFormEntry.caseenddate"
                  @input="modelToCalendarEntry = false"
                  >
                      <v-spacer></v-spacer>
                  </v-date-picker>
                </v-menu>
                <v-text-field
                v-else-if="modePreview == true"
                v-model="payloadFormEntry.caseenddate"
                prefix="To ** :"
                prepend-inner-icon="mdi-calendar-start"
                hide-details
                :outlined="modePreview == false"
                :readonly="modePreview == true"
                :dense="modePreview == false"
                ></v-text-field>
              </v-col>

              <v-col>
                <v-text-field
                v-model="payloadFormEntry.latitude"
                prefix="Latitude ** :"
                :outlined="modePreview == false"
                :readonly="modePreview == true"
                :dense="modePreview == false"
                hide-details
                @keypress="isNumber($event)"
                append-icon="mdi-latitude"
                ></v-text-field>
              </v-col>

              <v-col>
                <v-text-field
                v-model="payloadFormEntry.longitude"
                prefix="Longitude ** :"
                :outlined="modePreview == false"
                :readonly="modePreview == true"
                :dense="modePreview == false"
                hide-details
                @keypress="isNumber($event)"
                append-icon="mdi-longitude"
                ></v-text-field>
              </v-col>

            </v-row>

            <v-row>

              <v-col>
                <div>
                  <span>Report:</span>
                </div>
                <div>
                  <v-file-input
                  :outlined="modePreview == false"
                  :disabled="modePreview == true"
                  :dense="modePreview == false"
                  hide-details
                  placeholder="Upload File"
                  prepend-icon=""
                  prepend-inner-icon="mdi-paperclip"
                  v-model="filesEntry.fullreport"
                  @change="uploadFileEntry('fullreport')"
                  ></v-file-input>
                </div>
              </v-col>

              <v-col>
                <div>
                  <span>COC:</span>
                </div>
                <div>
                  <v-file-input
                  :outlined="modePreview == false"
                  :disabled="modePreview == true"
                  :dense="modePreview == false"
                  hide-details
                  placeholder="Upload File"
                  prepend-icon=""
                  prepend-inner-icon="mdi-paperclip"
                  v-model="filesEntry.coc"
                  @change="uploadFileEntry('coc')"
                  ></v-file-input>
                </div>
              </v-col>

              <v-col>
                <div>
                  <span>Executive Summary:</span>
                </div>
                <div>
                  <v-file-input
                  :outlined="modePreview == false"
                  :disabled="modePreview == true"
                  :dense="modePreview == false"
                  hide-details
                  placeholder="Upload File"
                  prepend-icon=""
                  prepend-inner-icon="mdi-paperclip"
                  v-model="filesEntry.executivesummary"
                  @change="uploadFileEntry('executivesummary')"
                  ></v-file-input>
                </div>
              </v-col>

              <v-col>
                <div>
                  <span>COA:</span>
                </div>
                <div>
                  <v-file-input
                  :outlined="modePreview == false"
                  :disabled="modePreview == true"
                  :dense="modePreview == false"
                  hide-details
                  placeholder="Upload File"
                  prepend-icon=""
                  prepend-inner-icon="mdi-paperclip"
                  v-model="filesEntry.coa"
                  @change="uploadFileEntry('coa')"
                  ></v-file-input>
                </div>
              </v-col>

              <v-col>
                <div>
                  <span>Sampling Data:</span>
                </div>
                <div>
                  <v-file-input
                  :outlined="modePreview == false"
                  :disabled="modePreview == true"
                  :dense="modePreview == false"
                  hide-details
                  placeholder="Upload File"
                  prepend-icon=""
                  prepend-inner-icon="mdi-paperclip"
                  v-model="filesEntry.filesampling"
                  @change="uploadFileEntry('filesampling')"
                  ></v-file-input>
                </div>
              </v-col>

            </v-row>

            <v-row>
              <v-col>
                <v-textarea
                v-model="payloadFormEntry.remarks"
                rows="3"
                label="Remarks"
                :outlined="modePreview == false"
                :readonly="modePreview == true"
                hide-details
                ></v-textarea>
              </v-col>
            </v-row>

            <v-divider></v-divider>

            <!-- Sampling Point -->
            <v-row>
              <v-col class="px-5">

                <v-row class="black--text text-subtitle-2 text-decoration-underline">
                  <v-col class="py-0">
                    Sample Point:
                  </v-col>
                </v-row>

                <v-row v-show="modePreview == false">
                  <v-col>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                        fab
                        x-small
                        color="primary"
                        class="mb-2 mr-2"
                        v-bind="attrs"
                        v-on="on"
                        @click="addSamplingPointEntry()"
                        >
                          <v-icon>
                            mdi-plus
                          </v-icon>
                        </v-btn>
                        <span class="mt-1 text-subtitle-1">Add Sampling Point</span>
                      </template>
                      <span>Add More Sampling Point</span>
                    </v-tooltip>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>

                    <v-expansion-panels v-model="panelEntry" :multiple="modePreview == true" :readonly="modePreview == true">
                      <v-expansion-panel
                      v-for="(item,i) in payloadFormEntry.pointinfo"
                      :key="i"
                      >

                        <v-expansion-panel-header
                        color="primary" 
                        class="white--text expansion-header cust-expansion-header"
                        >
                          <v-row justify="center">

                            <!-- Left -->
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                v-show="modePreview == false"
                                fab
                                x-small
                                color="red"
                                class="ma-0 pa-0 cust-remove-sampling-point-btn"
                                elevation="0"
                                v-bind="attrs"
                                v-on="on"
                                @click.stop="removeSamplingPointEntry(item,i)"
                                >
                                  <v-icon class="white--text">
                                    mdi-delete-forever
                                  </v-icon>
                                </v-btn>
                              </template>
                              <span>Remove this sample point</span>
                            </v-tooltip>

                            <v-spacer></v-spacer>

                            <!-- Center -->
                            <span class="d-flex align-center">
                              <v-icon dense class="white--text">mdi-map-marker</v-icon>
                              Sampling Point {{i+1}}
                            </span>

                            <v-spacer></v-spacer>

                          </v-row>
                        </v-expansion-panel-header>

                        <v-expansion-panel-content>
                          
                          <v-row>

                            <v-col>
                              <v-text-field
                              v-model="item.samplingpoint"
                              label="Point ID **"
                              :outlined="modePreview == false"
                              :readonly="modePreview == true"
                              :dense="modePreview == false"
                              hide-details
                              ></v-text-field>
                            </v-col>

                            <v-col>
                              <v-select
                              :items="listState"
                              v-model="item.pointstate"
                              label="State **"
                              :outlined="modePreview == false"
                              :readonly="modePreview == true"
                              :dense="modePreview == false"
                              hide-details
                              ></v-select>
                            </v-col>

                            <v-col>
                              <v-text-field
                              v-model="item.pointriver"
                              label="River **"
                              :outlined="modePreview == false"
                              :readonly="modePreview == true"
                              :dense="modePreview == false"
                              hide-details
                              ></v-text-field>
                            </v-col>

                          </v-row>

                          <v-row>

                            <v-col>
                              <v-text-field
                              v-model="item.pointlatitude"
                              label="Latitude **"
                              :outlined="modePreview == false"
                              :readonly="modePreview == true"
                              :dense="modePreview == false"
                              hide-details
                              @keypress="isNumber($event)"
                              ></v-text-field>
                            </v-col>

                            <v-col>
                              <v-text-field
                              v-model="item.pointlongitude"
                              label="Longitude **"
                              :outlined="modePreview == false"
                              :readonly="modePreview == true"
                              :dense="modePreview == false"
                              hide-details
                              @keypress="isNumber($event)"
                              ></v-text-field>
                            </v-col>

                          </v-row>

                          <v-row>

                            <v-col>
                              <v-textarea
                              v-model="item.locationdescription"
                              label="Location Description"
                              :outlined="modePreview == false"
                              :readonly="modePreview == true"
                              :dense="modePreview == false"
                              rows="4"
                              hide-details
                              ></v-textarea>
                            </v-col>

                          </v-row>

                          <v-row>

                            <v-col cols="3">
                              <v-text-field
                              v-model="item.samplingmatrix"
                              label="Sampling Matrix **"
                              :outlined="modePreview == false"
                              :readonly="modePreview == true"
                              :dense="modePreview == false"
                              hide-details
                              ></v-text-field>
                            </v-col>

                            <v-col cols="5" class="d-flex">
                              <v-file-input
                              label="Picture **"
                              :outlined="modePreview == false"
                              :disabled="modePreview == true"
                              :dense="modePreview == false"
                              hide-details
                              prepend-icon="mdi-camera"
                              placeholder="Upload File"
                              v-model="item.pictureInput"
                              @change="uploadFileEntry('picture',i)"
                              ></v-file-input>
                            </v-col>

                          </v-row>

                          <v-row>
                            
                            <v-col class="px-3">

                              <v-card class="px-3" outlined>

                                <v-row v-show="modePreview == false">
                                  <v-col class="d-flex">
                                    <v-btn
                                    fab
                                    x-small
                                    color="primary"
                                    class="mr-2"
                                    @click="addEntryFrequency(i)"
                                    >
                                      <v-icon>
                                        mdi-plus
                                      </v-icon>
                                    </v-btn>
                                    <span class="mt-1 text-subtitle-1">Add Frequency</span>
                                  </v-col>
                                </v-row>

                                <v-row
                                v-for="(subitem,x) in item.frequencyinfos"
                                :key="x"
                                >

                                  <v-col v-show="modePreview == false" cols="1" class="text-center pr-0">
                                    <v-btn
                                    color="red"
                                    fab
                                    x-small
                                    dark
                                    @click="removeEntryFrequency(i,x)"
                                    >
                                      <v-icon>
                                        mdi-minus
                                      </v-icon>
                                    </v-btn>
                                  </v-col>

                                  <v-col>
                                    <v-menu
                                    v-if="modePreview == false"
                                    v-model="subitem.modelCalendarFrequency"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                    >
                                      <template v-slot:activator="{ on, attrs }">
                                          <v-text-field
                                          label="Date **"
                                          v-model="subitem.frequencyDates"
                                          prepend-inner-icon="mdi-calendar"
                                          readonly
                                          v-bind="attrs"
                                          v-on="on"
                                          hide-details
                                          outlined
                                          dense
                                          ></v-text-field>
                                      </template>
                                      <v-date-picker
                                      v-model="subitem.frequencyDates"
                                      @input="subitem.modelCalendarFrequency = false"
                                      >
                                          <v-spacer></v-spacer>
                                      </v-date-picker>
                                    </v-menu>
                                    <v-text-field
                                    v-else-if="modePreview == true"
                                    label="Date **"
                                    v-model="subitem.frequencyDates"
                                    prepend-inner-icon="mdi-calendar"
                                    hide-details
                                    :outlined="modePreview == false"
                                    :readonly="modePreview == true"
                                    :dense="modePreview == false"
                                    ></v-text-field>
                                  </v-col>

                                  <v-col>
                                    <v-select
                                    :items="listHours"
                                    v-model="subitem.frequencyHours"
                                    prepend-inner-icon="mdi-clock-time-four-outline"
                                    label="Hours **"
                                    :outlined="modePreview == false"
                                    :readonly="modePreview == true"
                                    :dense="modePreview == false"
                                    hide-details
                                    ></v-select>
                                  </v-col>

                                  <v-col>
                                    <v-select
                                    :items="listMinutes"
                                    v-model="subitem.frequencyMins"
                                    prepend-inner-icon="mdi-clock-time-four-outline"
                                    label="Minutes **"
                                    :outlined="modePreview == false"
                                    :readonly="modePreview == true"
                                    :dense="modePreview == false"
                                    hide-details
                                    ></v-select>
                                  </v-col>

                                  <v-col>
                                    <v-text-field
                                    v-model="subitem.frequencydescription"
                                    label="Frequency Description **"
                                    :outlined="modePreview == false"
                                    :readonly="modePreview == true"
                                    :dense="modePreview == false"
                                    hide-details
                                    ></v-text-field>
                                  </v-col>

                                </v-row>

                              </v-card>

                            </v-col>

                          </v-row>

                        </v-expansion-panel-content>

                      </v-expansion-panel>
                    </v-expansion-panels>

                  </v-col>
                </v-row>

              </v-col>
            </v-row>

          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
            v-show="modePreview == false"
            dark
            color="button"
            @click="resetFormEntryForm()"
            >
              Reset
            </v-btn>
            <v-btn
            v-show="modePreview == true"
            dark
            color="button"
            @click="panelEntry = null;modePreview = false"
            >
              Back
            </v-btn>
            <v-btn
            v-show="modePreview == false"
            dark
            color="button"
            @click="changeToModePreview()"
            >
              Preview
            </v-btn>
            <v-btn
            v-show="modePreview == true"
            color="button"
            class="white--text"
            :disabled="disabledButtonSubmit()"
            @click="submitCaseEntry()"
            >
              Submit
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>

        </v-card>

      </div>

    </v-container>

    

</template>


<script>
import { bus } from '@/main';
import axios from 'axios';
import Papa from "papaparse";

export default {

    
    data: () => ({

      // General
      pathModul: [
          {
              text: 'Home',
              disabled: false,
              href: '/Mapviewer'
          },
          {
              text: 'Report - Investigative Study Data Entry',
              disabled: true,
              href: '/Report/RawData',
          },
      ],

      selectedBase: "case list",
      
      currentDatetime: "",
      listHours: [],
      listMinutes: [],
      listYear: [],
      modelSelectYear: "",

      // Case List
      loadingTableCaseList: false,
      thead_caselist: [
        { text: "State", value: "STATE", divider: true, align: "center", sortable: true, },
        { text: "Case ID", value: "REPORT_ID", divider: true, align: "center", sortable: true, },
        { text: "Case Name", value: "CASE_NAME", divider: true, align: "center", sortable: true, },
        { text: "Start Date", value: "CASE_START_DATE_UI", divider: true, align: "center", sortable: true, },
        { text: "End Date", value: "CASE_END_DATE_UI", divider: true, align: "center", sortable: true, },
        { text: "Created By", value: "CREATED_BY", divider: true, align: "center", sortable: true, },
        { text: "Created By", value: "CREATED_BY", divider: true, align: "center", sortable: true, },
        { text: "Action", value: "Action", divider: true, align: "center", sortable: true, },
      ],
      tbody_caselist: [],

      selectedCaseToDelete: "",

      dialogForm: false,
      dialogConfirmationDelete: false,
      modelFromCalendar: false,
      modelToCalendar: false,
      listState: ['PERLIS', 'KEDAH', 'PULAU PINANG', 'PERAK', 'SELANGOR', 'WP KUALA LUMPUR', 'NEGERI SEMBILAN', 'MELAKA', 'JOHOR', 'PAHANG', 'TERANGGANU', 'KELANTAN', 'SABAH', 'SARAWAK', 'WP PUTRAJAYA', 'WP LABUAN'],
      filesEdit: {
        fullreport: null,
        fullreport_url: "",
        coc: null,
        coc_url: "",
        executivesummary: null,
        executivesummary_url: "",
        coa: null,
        coa_url: "",
        filesampling: null,
        filesampling_url: "",
        picture: null,
        picture_url: "",
      },
      payloadFormEdit: {
        id: "",
        river: "",
        basin: "",
        locationdesc: "",
        createdby: "",
        createddt: "",
        reportid: "",
        casetitle: "",
        casename: "",
        state: "",
        casestartdate: "",
        caseenddate: "",
        latitude: "",
        longitude: "",
        fullreport: "",
        coc: "",
        executivesummary: "",
        coa: "",
        filesampling: "",
        remarks: "",
        pointinfo: []
      },

      panel: null,

      // Data Entry Form
      modelFromCalendarEntry: false,
      modelToCalendarEntry: false,
      payloadFormEntry: {
        reportid: "",
        casetitle: "",
        casename: "",
        state: "",
        casestartdate: "",
        caseenddate: "",
        latitude: "",
        longitude: "",
        fullreport: "",
        coc: "",
        executivesummary: "",
        coa: "",
        filesampling: "",
        remarks: "",
        pointinfo: []
      },
      filesEntry: {
        fullreport: null,
        fullreport_url: "",
        coc: null,
        coc_url: "",
        executivesummary: null,
        executivesummary_url: "",
        coa: null,
        coa_url: "",
        filesampling: null,
        filesampling_url: "",
        picture: null,
        picture_url: "",
      },

      panelEntry: null,

      modePreview: false,

        
    }),

    mounted() {
      
      this.getCurrentDatetime();
      this.getListHours();
      this.getListMinutes();
      this.getListYear();

    },

    methods: {

      getCurrentDatetime(){

        this.currentDatetime = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString()

      },

      getListHours(){

        this.listHours = [];

        for (let i = 0; i < 25; i++) {
          this.listHours.push(i < 10? "0"+i.toString() : i.toString())
        }

      },

      getListMinutes(){

        this.listMinutes = [];

        for (let i = 0; i < 60; i++) {
          this.listMinutes.push(i < 10? "0"+i.toString() : i.toString())
        }

      },

      getListYear(){

        this.listYear = [];

        axios.get(this.globalUrl+'mqm2/miqims/years', {
            headers: {
                'Authorization': 'Bearer ' + this.$store.getters.user.token,
            }
        })
        .then((response) => {

            let data = response.data;

            this.listYear = data.sort();
            
        })
        .catch(error => {
            console.log(error);
        })

      },

      generateReportCaseList(){

        this.tbody_caselist = [];

        this.loadingTableCaseList = true;

        axios.get(this.globalUrl+'mqm2/miqims/summary_invstudy?year='+this.modelSelectYear, {
            headers: {
                'Authorization': 'Bearer ' + this.$store.getters.user.token,
            }
        })
        .then((response) => {

            let data = response.data;

            console.log(data);

            for(let i in data){
              data[i]["CASE_START_DATE_UI"] = this.useConvertDisplayDate(data[i]["CASE_START_DATE"]);
              data[i]["CASE_END_DATE_UI"] = this.useConvertDisplayDate(data[i]["CASE_END_DATE"]);
            }

            this.tbody_caselist = data;

            this.loadingTableCaseList = false;
            
        })
        .catch(error => {
            console.log(error);
            this.loadingTableCaseList = false;
        })

      },


      customSort: function(items, index, isDesc) {
        //   console.log(items, index, isDesc);

        // console.log(index);

        items.sort((a, b) => {
          if (index[0] == "CASE_START_DATE_UI" || index[0] == "CASE_END_DATE_UI") {
            //   console.log(new Date(this.standardDtFormatNuzul(b[index])));
            if (!isDesc[0]) {
              return (
                new Date(this.useConvertDatabaseDate(b[index])) -
                new Date(this.useConvertDatabaseDate(a[index]))
              );
            } else {
              return (
                new Date(this.useConvertDatabaseDate(a[index])) -
                new Date(this.useConvertDatabaseDate(b[index]))
              );
            }
          } else {
            if (typeof a[index] !== "undefined") {
              if (!isDesc[0]) {
                return a[index]
                  .toLowerCase()
                  .localeCompare(b[index].toLowerCase());
              } else {
                return b[index]
                  .toLowerCase()
                  .localeCompare(a[index].toLowerCase());
              }
            }
          }
        });
        return items;
      },

      openFormEditCaseList(dataSelected){

        axios.get(this.globalUrl+'mqm2/miqims/edit_getinfofirst_invstudy?caseid='+dataSelected.REPORT_ID, {
            headers: {
                'Authorization': 'Bearer ' + this.$store.getters.user.token,
            }
        })
        .then((response) => {

            let data = response.data;

            for(let i in data.pointinfo){
              data.pointinfo[i]["pictureInput"] = null
              data.pointinfo[i]["picturepath_new"] = ""
            }

            for(let i in data.pointinfo){
              for(let x in data.pointinfo[i].frequencyinfos){

                data.pointinfo[i].frequencyinfos[x]["modelCalendarFrequency"] = false;

                data.pointinfo[i].frequencyinfos[x]["frequencyDates"] = data.pointinfo[i].frequencyinfos[x]["frequencydt"].split("T")[0];
                data.pointinfo[i].frequencyinfos[x]["frequencyHours"] = data.pointinfo[i].frequencyinfos[x]["frequencydt"].split("T")[1].split(":")[0];
                data.pointinfo[i].frequencyinfos[x]["frequencyMins"] = data.pointinfo[i].frequencyinfos[x]["frequencydt"].split("T")[1].split(":")[1];
                
                // console.log(data.pointinfo[i].frequencyinfos[x]["frequencydt"]);
              }
            }

            console.log(data.pointinfo[0].frequencyinfos);
          
            this.payloadFormEdit = data;
            
            this.panel = null;

            this.dialogForm = true
            
        })
        .catch(error => {
            console.log(error);
            this.dialogForm = true
        })

      },

      viewFile(url){
        window.open(url, '_blank');
      },

      uploadFile(view,index){
        // console.log(view);

        let fileModel = null

        if(view != "picture"){

          if(this.filesEdit[view] != undefined){
            fileModel = this.filesEdit[view]
          }
          else{
            this.filesEdit[view+"_url"] = "";
          }

        }
        else{
          fileModel = this.payloadFormEdit.pointinfo[index]["pictureInput"]
        }

        if(fileModel != null && fileModel != undefined){

          let formData = new FormData();

          formData.append("files", fileModel);

          axios.post(this.globalUrl+'mqm2/miqims/upload',formData, {
              headers: {
                  'Authorization': 'Bearer ' + this.$store.getters.user.token,
              }
          })
          .then((response) => {

              let data = response.data;

              if(view != "picture"){
                this.filesEdit[view+"_url"] = data.filepath;
              }
              else{
                this.payloadFormEdit.pointinfo[index]["picturepath_new"] = data.filepath;
              }
              
          })
          .catch(error => {
              console.log(error);
              this.dialogForm = true
          })

        }

        

        

      },

      addSamplingPointEdit(){
        
        // add new sampling
        this.payloadFormEdit.pointinfo.push({
          samplingpoint: "",
          pointstate: "",
          pointriver: "",
          pointlatitude: "",
          pointlongitude: "",
          samplingmatrix: "",
          locationdescription: "",
          pictureInput: null,
          picturepath: "",
          picturepath_new: "",
        })

        // expand last index of array (new sampling point)
        let arr = this.payloadFormEdit.pointinfo
        this.panel = arr.length - 1

      },

      removeSamplingPointEdit(data,index){

        // prevent button remove sampling point maing action of expand
        const curr = this.panel
        this.panel = curr === undefined ? undefined : undefined

        // remove selected sampling point by index selected
        this.payloadFormEdit.pointinfo.splice(index, 1);

      },

      addEditFrequency(index,subindex){

        this.payloadFormEdit.pointinfo[index].frequencyinfos.push({
          modelCalendarFrequency: false,
          frequencyDates: this.currentDatetime.split("T")[0],
          frequencyHours: this.currentDatetime.split("T")[1].split(":")[0],
          frequencyMins: this.currentDatetime.split("T")[1].split(":")[1],
          frequencydescription: "",
          frequencydt: "",
        })

      },

      removeEditFrequency(index,subindex){

        this.payloadFormEdit.pointinfo[index].frequencyinfos.splice(subindex, 1);

      },

      saveEditCaseList(){

        let keyfiles = ["fullreport","coc","executivesummary","coa","filesampling"]

        for(let i in keyfiles){
          if(this.filesEdit[keyfiles[i]+"_url"] != ""){
            this.payloadFormEdit[keyfiles[i]] = this.filesEdit[keyfiles[i]+"_url"]
          }
        }

        for(let i in this.payloadFormEdit.pointinfo){
          if(this.payloadFormEdit.pointinfo[i].picturepath_new != ""){
            this.payloadFormEdit.pointinfo[i].picturepath = this.payloadFormEdit.pointinfo[i].picturepath_new
          }
        }

        for(let i in this.payloadFormEdit.pointinfo){
          for(let x in this.payloadFormEdit.pointinfo[i].frequencyinfos){
            this.payloadFormEdit.pointinfo[i].frequencyinfos[x]["frequencydt"] = this.payloadFormEdit.pointinfo[i].frequencyinfos[x]["frequencyDates"] + "T" + this.payloadFormEdit.pointinfo[i].frequencyinfos[x]["frequencyHours"] + ":" + this.payloadFormEdit.pointinfo[i].frequencyinfos[x]["frequencyMins"] + ":00"
          }
        }

        console.log("payload",this.payloadFormEdit);

        axios.put(this.globalUrl+'mqm2/miqims/editcaseid_invstudy',this.payloadFormEdit, {
            headers: {
                'Authorization': 'Bearer ' + this.$store.getters.user.token,
            }
        })
        .then((response) => {

            let data = response.data;
            console.log(data);

            bus.$emit("popupMessage",{
              status: "success",
              title: "Edit Case",
              message: "Case details successfully update",
            })

            this.dialogForm = false;

            this.generateReportCaseList();
            
        })
        .catch(error => {
            console.log(error);
            bus.$emit("popupMessage",{
              status: "error",
              title: "Edit Case",
              message: "Something went wrong. Please try again.",
            })
        })

      },

      openConfirmationDeleteCaseList(data){
        this.selectedCaseToDelete = data.REPORT_ID
        this.dialogConfirmationDelete = true;
      },

      yesConfirmDelete(caseID){
        console.log(caseID);

        axios.get(this.globalUrl+'mqm2/miqims/delcaseid_invstudy?caseid='+caseID, {
            headers: {
                'Authorization': 'Bearer ' + this.$store.getters.user.token,
            }
        })
        .then((response) => {

            let data = response.data;
            console.log(data);

            bus.$emit("popupMessage",{
              status: "success",
              title: "Edit Case",
              message: "Case details successfully deleted",
            })

            this.dialogConfirmationDelete = false;

            this.generateReportCaseList();
            
        })
        .catch(error => {
            console.log(error);
            bus.$emit("popupMessage",{
              status: "error",
              title: "Edit Case",
              message: "Something went wrong. Please try again.",
            })
        })
      },

      uploadFileEntry(view,index){

        let fileModel = null

        if(view != "picture"){

          if(this.filesEntry[view] != undefined){
            fileModel = this.filesEntry[view]
          }
          else{
            this.filesEntry[view+"_url"] = "";
          }

        }
        else{
          fileModel = this.payloadFormEntry.pointinfo[index]["pictureInput"]
        }

        if(fileModel != null && fileModel != undefined){

          let formData = new FormData();

          formData.append("files", fileModel);

          axios.post(this.globalUrl+'mqm2/miqims/upload',formData, {
              headers: {
                  'Authorization': 'Bearer ' + this.$store.getters.user.token,
              }
          })
          .then((response) => {

              let data = response.data;

              if(view != "picture"){
                this.filesEntry[view+"_url"] = data.filepath;
              }
              else{
                this.payloadFormEntry.pointinfo[index]["picturepath_new"] = data.filepath;
              }
              
          })
          .catch(error => {
              console.log(error);
              this.dialogForm = true
          })

        }

      },

      addSamplingPointEntry(){

        // add new sampling
        this.payloadFormEntry.pointinfo.push({
          samplingpoint: "",
          pointstate: "",
          pointriver: "",
          pointlatitude: "",
          pointlongitude: "",
          samplingmatrix: "",
          locationdescription: "",
          pictureInput: null,
          picturepath: "",
          picturepath_new: "",
          frequencyinfos: []
        })

        // expand last index of array (new sampling point)
        let arr = this.payloadFormEntry.pointinfo
        this.panelEntry = arr.length - 1
        


      },

      removeSamplingPointEntry(data,index){

        // prevent button remove sampling point maing action of expand
        const curr = this.panelEntry
        this.panelEntry = curr === undefined ? undefined : undefined

        // remove selected sampling point by index selected
        this.payloadFormEntry.pointinfo.splice(index, 1);

      },

      addEntryFrequency(index){

        this.payloadFormEntry.pointinfo[index].frequencyinfos.push({
          modelCalendarFrequency: false,
          frequencyDates: this.currentDatetime.split("T")[0],
          frequencyHours: this.currentDatetime.split("T")[1].split(":")[0],
          frequencyMins: this.currentDatetime.split("T")[1].split(":")[1],
          frequencydescription: "",
          frequencydt: "",
        })

      },

      removeEntryFrequency(index,subindex){
        this.payloadFormEntry.pointinfo[index].frequencyinfos.splice(subindex, 1);
      },

      resetFormEntryForm(){

        this.payloadFormEntry = {
          reportid: "",
          casetitle: "",
          casename: "",
          state: "",
          casestartdate: "",
          caseenddate: "",
          latitude: "",
          longitude: "",
          fullreport: "",
          coc: "",
          executivesummary: "",
          coa: "",
          filesampling: "",
          remarks: "",
          pointinfo: []
        };

        this.filesEntry = {
          fullreport: null,
          fullreport_url: "",
          coc: null,
          coc_url: "",
          executivesummary: null,
          executivesummary_url: "",
          coa: null,
          coa_url: "",
          filesampling: null,
          filesampling_url: "",
          picture: null,
          picture_url: "",
        };

        this.panelEntry = null

      },

      changeToModePreview(){

        this.panelEntry = null;
        this.panelEntry = [];

        for(let i in this.payloadFormEntry.pointinfo){
          this.panelEntry.push(parseInt(i))
        }

        this.modePreview = true;

      },

      disabledButtonSubmit(){

        let disable = false;

        if(this.payloadFormEntry.pointinfo.length == 0){

          if(
            this.payloadFormEntry.reportid == "" ||
            this.payloadFormEntry.casetitle == "" ||
            this.payloadFormEntry.casename == "" ||
            this.payloadFormEntry.state == "" ||
            this.payloadFormEntry.casestartdate == "" ||
            this.payloadFormEntry.caseenddate == "" ||
            this.payloadFormEntry.latitude == "" ||
            this.payloadFormEntry.longitude == ""
            // this.payloadFormEntry.fullreport == "" ||
            // this.payloadFormEntry.coc == "" ||
            // this.payloadFormEntry.executivesummary == "" ||
            // this.payloadFormEntry.coa == "" ||
            // this.payloadFormEntry.filesampling == "" ||
            // this.payloadFormEntry.remarks == ""
            // this.payloadFormEntry.pointinfo.length == 0
          ){
            disable = true
          }
          else{
            disable = false
          }

        }
        else{

          if(
            this.payloadFormEntry.reportid == "" ||
            this.payloadFormEntry.casetitle == "" ||
            this.payloadFormEntry.casename == "" ||
            this.payloadFormEntry.state == "" ||
            this.payloadFormEntry.casestartdate == "" ||
            this.payloadFormEntry.caseenddate == "" ||
            this.payloadFormEntry.latitude == "" ||
            this.payloadFormEntry.longitude == ""
            // this.payloadFormEntry.fullreport == "" ||
            // this.payloadFormEntry.coc == "" ||
            // this.payloadFormEntry.executivesummary == "" ||
            // this.payloadFormEntry.coa == "" ||
            // this.payloadFormEntry.filesampling == "" ||
            // this.payloadFormEntry.remarks == ""
            // this.payloadFormEntry.pointinfo.length == 0
          ){
            disable = true
          }
          else{
            
            for(let i in this.payloadFormEntry.pointinfo){

              if(this.payloadFormEntry.pointinfo[i].frequencyinfos.length == 0){

                if(
                  this.payloadFormEntry.pointinfo[i].samplingpoint == "" ||
                  this.payloadFormEntry.pointinfo[i].pointstate == "" ||
                  this.payloadFormEntry.pointinfo[i].pointriver == "" ||
                  this.payloadFormEntry.pointinfo[i].pointlatitude == "" ||
                  this.payloadFormEntry.pointinfo[i].pointlongitude == "" ||
                  this.payloadFormEntry.pointinfo[i].samplingmatrix == "" ||
                  this.payloadFormEntry.pointinfo[i].picturepath_new == ""
                ){
                  disable = true
                }
                else{
                  disable = false
                }

              }
              else{

                if(
                  this.payloadFormEntry.pointinfo[i].samplingpoint == "" ||
                  this.payloadFormEntry.pointinfo[i].pointstate == "" ||
                  this.payloadFormEntry.pointinfo[i].pointriver == "" ||
                  this.payloadFormEntry.pointinfo[i].pointlatitude == "" ||
                  this.payloadFormEntry.pointinfo[i].pointlongitude == "" ||
                  this.payloadFormEntry.pointinfo[i].samplingmatrix == "" ||
                  this.payloadFormEntry.pointinfo[i].picturepath_new == ""
                ){
                  disable = true
                }
                else{
                  
                  for(let x in this.payloadFormEntry.pointinfo[i].frequencyinfos){

                    if(
                      this.payloadFormEntry.pointinfo[i].frequencyinfos[x].frequencyDates == "" ||
                      this.payloadFormEntry.pointinfo[i].frequencyinfos[x].frequencyHours == "" ||
                      this.payloadFormEntry.pointinfo[i].frequencyinfos[x].frequencyMins == "" ||
                      this.payloadFormEntry.pointinfo[i].frequencyinfos[x].frequencydescription == ""
                    ){
                      disable = true
                    }
                    else{
                      disable = false
                    }

                  }
                  
                }

              }
              
            }

          }

        }

        return disable;

        

      },

      submitCaseEntry(){

        let keyfiles = ["fullreport","coc","executivesummary","coa","filesampling"]

        for(let i in keyfiles){
          if(this.filesEntry[keyfiles[i]+"_url"] != ""){
            this.payloadFormEntry[keyfiles[i]] = this.filesEntry[keyfiles[i]+"_url"]
          }
        }

        for(let i in this.payloadFormEntry.pointinfo){
          if(this.payloadFormEntry.pointinfo[i].picturepath_new != ""){
            this.payloadFormEntry.pointinfo[i].picturepath = this.payloadFormEntry.pointinfo[i].picturepath_new
          }
        }

        for(let i in this.payloadFormEntry.pointinfo){
          for(let x in this.payloadFormEntry.pointinfo[i].frequencyinfos){
            this.payloadFormEntry.pointinfo[i].frequencyinfos[x]["frequencydt"] = this.payloadFormEntry.pointinfo[i].frequencyinfos[x]["frequencyDates"] + "T" + this.payloadFormEntry.pointinfo[i].frequencyinfos[x]["frequencyHours"] + ":" + this.payloadFormEntry.pointinfo[i].frequencyinfos[x]["frequencyMins"] + ":00"
          }
        }

        console.log("payload",this.payloadFormEntry);

        axios.post(this.globalUrl+'mqm2/miqims/addnewcase_invstudy',this.payloadFormEntry, {
            headers: {
                'Authorization': 'Bearer ' + this.$store.getters.user.token,
            }
        })
        .then((response) => {

            let data = response.data;
            console.log(data);

            bus.$emit("popupMessage",{
              status: "success",
              title: "Add New Case",
              message: "New Case successfully added",
            })

            this.resetFormEntryForm()
            
        })
        .catch(error => {
            console.log(error);
            bus.$emit("popupMessage",{
              status: "error",
              title: "Add New Case",
              message: "Something went wrong. Please try again.",
            })
        })

      }


      
    },
    

}
</script>



<style lang="scss">

    @import '~scss/main';

    .freq_table > .v-data-table__wrapper > table > tbody > tr > td{
      border-bottom: none !important;
    } 

    /* Footer Report */
    .one-text-footer{
      margin: 0px 10px;
    }

    .modified_input > .v-input__control > .v-input__slot > fieldset{
      height: 35px !important;
    }

    .expansion-header > .v-expansion-panel-header__icon > i{
      color: white !important;
    }

    // .cust-expansion-header{
    //   pointer-events: none;
    // }
    
    // .cust-expansion-header > .cust-remove-sampling-point-btn{
    //   pointer-events: none;
    // }

    // .cust-expansion-header > .v-expansion-panel-header__icon{
    //   pointer-events: All;
    // }


</style>